exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-consulta-js": () => import("./../../../src/pages/consulta.js" /* webpackChunkName: "component---src-pages-consulta-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-harmonizacao-facial-js": () => import("./../../../src/pages/harmonizacao-facial.js" /* webpackChunkName: "component---src-pages-harmonizacao-facial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mais-saude-js": () => import("./../../../src/pages/mais-saude.js" /* webpackChunkName: "component---src-pages-mais-saude-js" */),
  "component---src-pages-medicina-dentaria-js": () => import("./../../../src/pages/medicina-dentaria.js" /* webpackChunkName: "component---src-pages-medicina-dentaria-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-servico-js": () => import("./../../../src/templates/servico.js" /* webpackChunkName: "component---src-templates-servico-js" */)
}

